<template>
  <main :class="izd">
    <SliderComponent
      :title="titles.hero[izd]"
      :izd="izd"
      :className="'slider-component__title'"
      :sliderList="heroSliderList[izd]"
    />
    <FormComponent
      :title="titles.firstForm"
      :izd="izd"
      :type="'call'"
      :clientMail="clientMail"
    />
    <HowWeWorkComponent :title="titles.howWeWork" />
    <AdvantagesComponent :title="titles.advantages" />
    <FormComponent
      :title="titles.secondForm"
      :izd="izd"
      :type="'measure'"
      :clientMail="clientMail"
    />
    <PriceComponent :title="titles.price" :izd="izd" />
    <FormComponent
      :title="titles.thirdForm"
      :izd="izd"
      :type="'calculation'"
      :clientMail="clientMail"
    />
    <AboutUsComponent :title="titles.aboutUs" />
    <!-- <SliderComponent
      :title="titles.ourWork"
      :izd="'ourWork'"
      :sliderList="ourWorkSliderList"
    /> -->
    <!-- <OurWorkComponent/> -->
    <AccordeonComponent :title="titles.faq" />
    <ReviewsComponent :title="titles.reviews" />
    <FormComponent :title="titles.fourthForm" :type="'call'" :izd="izd" :clientMail="clientMail" />
  </main>
</template>

<script>
import SliderComponent from "./Slider/SliderComponent.vue";
import FormComponent from "./Form/FormComponent.vue";
import HowWeWorkComponent from "./HowWeWork/HowWeWorkComponent.vue";
import AdvantagesComponent from "./Advantages/AdvantagesComponent.vue";
import PriceComponent from "./Price/PriceComponent.vue";
import AboutUsComponent from "./AboutUs/AboutUsComponent.vue";
// import OurWorkComponent from './OurWork/OurWorkComponent.vue'
import AccordeonComponent from "./Accordeon/AccordeonComponent.vue";
import ReviewsComponent from "./Reviews/ReviewsComponent.vue";
import router from "@/router";
export default {
  name: "MainComponent",
  components: {
    SliderComponent,
    FormComponent,
    HowWeWorkComponent,
    AdvantagesComponent,
    PriceComponent,
    AboutUsComponent,
    AccordeonComponent,
    ReviewsComponent,
  },
  props: ["izd"],
  data() {
    return {
      // izd: 'rolo',
      clientMail: ['mail@sbdecor.ru', 'zakaz@rznforoom.ru', 'frontprstvasya@gmail.com'],
      // ourWorkSliderList: [
      //   {
      //     img: "slide.png",
      //     text: [
      //       "Рулонные шторы: ROLL",
      //       "Установка на проем",
      //       "Размер 180х180",
      //       "Ткань: какая-то",
      //     ],
      //   },
      //   {
      //     img: "slide.png",
      //     text: [
      //       "Рулонные шторы: ROLL",
      //       "Установка на проем",
      //       "Размер 180х180",
      //       "Ткань: какая-то",
      //     ],
      //   },
      //   {
      //     img: "slide.png",
      //     text: [
      //       "Рулонные шторы: ROLL",
      //       "Установка на проем",
      //       "Размер 180х180",
      //       "Ткань: какая-то",
      //     ],
      //   },
      // ],
      heroSliderList: {
        rolo: [
          { img: "7.jpg" },
      
          { img: "1.jpg" },
          { img: "2.jpg" },
          { img: "3.jpg" },
          { img: "4.jpg" },
          { img: "5.jpg" },
          { img: "6.jpg" },
        ],
        gform: [{ img: "5.jpg" },{ img: "1.jpg" }, { img: "2.jpg" }, { img: "3.jpg" }, { img: "4.jpg" }],
        roma: [{ img: "4.jpg" }, { img: "1.jpg" }, { img: "2.jpg" }, { img: "3.jpg" }],
        plisse: [{ img: "4.jpg" },{ img: "1.jpg" }, { img: "2.jpg" }, { img: "3.jpg" }],
        vform: [{ img: "5.jpg" }, { img: "1.jpg" }, { img: "2.jpg" }, { img: "3.jpg" }, { img: "4.jpg" }],
      },
      titles: {
        hero: {
          gform: "Горизонтальные жалюзи",
          rolo: "Рулонные шторы",
          roma: "Римские шторы",
          plisse: "Шторы Плиссе",
          vform: "Вертикальные жалюзи",
        },
        firstForm: "Отправьте заявку с сайта и получите скидку по предложению месяца, дополнительную скидку 10%  и бесплатный замер в подарок!",
        howWeWork: "Как мы работаем",
        advantages: "Преимущества работы с нами",
        secondForm: "Оставьте заявку на замер",
        price: "Стоимость",
        thirdForm: "Рассчитать стоимость",
        aboutUs: "Об организации",
        ourWork: "Наши работы",
        faq: "Ответы на вопросы",
        reviews: "Отзывы",
        fourthForm: "Отправьте заявку с сайта и получите скидку по предложению месяца, дополнительную скидку 10%  и бесплатный замер в подарок!",
      },
    };
  },
  mounted() {
    this.$store.dispatch("counter");
    if (!this.izd) {
      router.push("/rolo");
    }
  },
  watch:{
      izd(value){
        this.$store.dispatch('setIzd', value); 
      },
    },
  computed: {
    counter() {
      return this.$store.getters.counter;
    },
  },
};
</script>
